import axios from 'axios';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  }
);

export default instance;
<template>
  <Header />
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <div class="flex-fill">
              <h4>Download List</h4>
              <span v-if="loading" class="text-danger">Loading...</span>
              <span v-if="!loading" class="text-success">Total downloads: {{ downloads.length }}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="text-center p-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-if="!loading" class="mt-1">
            <div class="table-responsive">
              <table class="table table-border">
                <thead>
                  <tr>
                    <th>MSS Order ID</th>
                    <th>IP</th>
                    <th>Date Added</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(download) in downloads" :key="download.id">
                    <td>
                      <router-link :to="{ name: 'mssedit', params: { id: download.mss_id } }">{{ download.mss_id }}</router-link>
                    </td>
                    <td>{{ download.ip }}</td>
                    <td>{{ download.created_at }}</td>
                    <td>{{ download.updated_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import * as Utils from '@/utils.js';
import Header from '@/components/panel/Header.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      downloads: [],
      loading: true
    }
  },
  async created() {
    await this.getDownloads()
  },
  methods: {
    async getDownloads() {
      const response = await ApiService.getDownloadList()

      this.downloads = response.map(download => {
        download.created_at = Utils.formatDate(download.created_at)
        download.updated_at = Utils.formatDate(download.updated_at)
        return download
      });

      this.loading = false
    },
  }
}
</script>
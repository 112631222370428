import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import "vue-toastification/dist/index.css";
import '@/assets/style.css'

createApp(App).use(router).use(Toast).mount('#app')

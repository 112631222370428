<template>
    <div>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <router-link class="navbar-brand" :to="{ name: 'mss' }">uPanel</router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav w-100">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'mss' }" exact-active-class="active">MSS</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'opencart' }"
                                exact-active-class="active">Opencart</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'downloads' }"
                                exact-active-class="active">Downloads</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'email' }" exact-active-class="active">Send
                                Email</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'license' }" exact-active-class="active">License
                                Tool</router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="logout" @click.prevent="logout">Logout</a>
                        </li>
                    </ul>
                    <form class="d-flex search-form">
                        <div class="input-group">
                            <input class="form-control" placeholder="Search" v-model="searchTerm">
                            <span class="input-clear" v-if="searchTerm" @click="clearSearchTerm">&times;</span>
                        </div>
                        <div v-if="submitted" class="search-results">
                            <div v-if="searchResults.length">
                                <div v-for="(order) in searchResults" :key="order.id" class="list border-bottom">
                                    <div class="d-flex flex-column">
                                        <span><router-link :to="{ name: 'mssedit', params: { id: order.id } }">#{{ order.id }}</router-link> {{ order.name }}</span>
                                        <small>Domain: {{ order.domain }}</small>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!searchResults.length" class="d-flex flex-column">
                                No results found
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import ApiService from '@/services/ApiService'

export default {
  data() {
    return {
      searchTerm: '',
      searchResults: [],
      debounceTimer: null,
      submitted: false
    };
  },
  watch: {
    searchTerm(newSearchTerm) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.fetchSearchResults(newSearchTerm);
      }, 300);
    }
  },
  methods: {
    async fetchSearchResults(searchTerm) {
        this.submitted = false
        this.searchResults = []
        if (searchTerm.length > 2) {
            this.searchResults = await ApiService.searchMss(searchTerm)
            this.submitted = true
        }
    },
    clearSearchTerm() {
        this.submitted = false;
        this.searchResults = [];
        this.searchTerm = '';
    },
    async logout() {
        await ApiService.logout()
        this.$router.push({ name: 'login' })
    }
  }
}
</script>
<style scoped>
.navbar-nav .nav-link {
    padding: 8px 14px !important;
}

.search-form {
    position: relative;
    width: 400px;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    z-index: 1;
}
.input-group {
    position: relative;
}
.input-clear {
    font-size: 25px;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    opacity: 1;
}
</style>
<template>
  <Header />
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <div class="flex-fill">
              <h4>Opencart Order List</h4>
              <span v-if="loading" class="text-danger">Loading...</span>
              <span v-if="!loading" class="text-success">
                <strong>Total orders:</strong> {{ orders.length }}<br><strong>Grand total:</strong> ${{ grandTotal }}
              </span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="text-center p-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-if="!loading" class="mt-1">
            <div class="table-responsive">
              <table class="table table-border">
                <thead>
                  <tr>
                    <th class="text-nowrap">Order ID</th>
                    <th class="text-nowrap">Order Status</th>
                    <th class="text-nowrap">Total</th>
                    <th class="text-nowrap">Extension ID</th>
                    <th class="text-nowrap">Extension Name</th>
                    <th class="text-nowrap">Username</th>
                    <th class="text-nowrap">Email</th>
                    <th class="text-nowrap">Date Added</th>
                    <!-- <th class="text-nowrap">Date Updated</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order) in orders" :key="order.id">
                    <td>{{ order.order_id }}</td>
                    <td :class="{
                        'text-danger': order.order_status == 'Refunded',
                        'text-success': order.order_status == 'Complete',
                        'text-warning': order.order_status != 'Refunded' && order.order_status != 'Complete',
                      }">
                        {{ order.order_status }}
                    </td>
                    <td>${{ order.total / 100 }}</td>
                    <td>{{ order.extension_id }}</td>
                    <td>{{ order.extension }}</td>
                    <td>{{ order.username }}</td>
                    <td>{{ order.email }}</td>
                    <td>{{ order.created_at }}</td>
                    <!-- <td>{{ order.updated_at }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import * as Utils from '@/utils.js';
import Header from '@/components/panel/Header.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      orders: [],
      loading: true,
      grandTotal: 0,
    }
  },
  async created() {
    await this.getOrders()
  },
  methods: {
    async getOrders() {
      const response = await ApiService.getOpencartOrderList()

      this.orders = response.map(order => {
        order.created_at = Utils.formatDate(order.created_at)
        order.updated_at = Utils.formatDate(order.updated_at)
        return order
      });

      if (this.orders && Array.isArray(this.orders)) {
        this.grandTotal = this.orders.reduce((total, order) => {
          if (order.total !== null) {
            return (total + parseInt(order.total) / 100);
          } else {
            return total;
          }
        }, 0);
      }

      this.loading = false
    },
  }
}
</script>
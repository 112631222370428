<template>
    <div>
        <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-md-6">
                    <h2 class="text-center mb-4">Login</h2>
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" name="email"  v-model="email">
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" name="password"  v-model="password">
                        </div>
                        <div class="d-grid gap-2">
                            <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService'

export default {
    data() {
        return {
            email: '',
            password: '',
            loading: true,
        }
    },
    methods: {
        async submitForm() {
            await ApiService.login({ email: this.email, password: this.password })
            this.loading = false
            this.$router.push({ name: 'mss' })
        }
    }
}
</script>
<template>
    <Header />
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <div class="flex-fill">
                        <h4>License Tool</h4>
                        <span class="text-success">Create or validate a license</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="mt-3 mb-5">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-3">
                                    <label class="form-label">License/Domain:</label>
                                    <input type="text" class="form-control" v-model="string">
                                </div>
                                <div class="mb-3">
                                    {{ result }}
                                </div>
                                <div class="text-center mt-5 mb-3">
                                    <button type="button" class="btn btn-primary" @click="getLicense" :disabled="isGetSubmitted">
                                        <span v-show="isGetSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Get License
                                    </button>
                                    <button type="button" class="btn btn-primary ms-2" @click="validateLicense" :disabled="isValidateSubmitted">
                                        <span v-show="isValidateSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Validate License
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import { useToast } from "vue-toastification"
import Header from '@/components/panel/Header.vue'

export default {
    components: {
        Header
    },
    data() {
        return {
            string: null,
            isGetSubmitted: false,
            isValidateSubmitted: false,
            result: null,
            toast: useToast(),
        }
    },
    methods: {
        validateDomain(domain) {
            return !/https?:\/\/|www\./.test(domain);
        },
        async getLicense() {
            this.result = null

            if (this.string === null || this.string === '' ) {
                this.toast.error('Please enter a domain name')
                return
            }

            if (!this.validateDomain(this.string)) {
                this.toast.error("Please enter the domain name without http:// or www.")
                return
            }

            this.isGetSubmitted = true

            const response = await ApiService.getMssLicense({domain: this.string})

            this.result = response.license

            this.isGetSubmitted = false

            this.toast.success('License retrieved successfully')
        },
        async validateLicense() {
            this.result = null

            if (this.string === null || this.string === '' ) {
                this.toast.error('Please enter a license')
                return
            }

            this.isValidateSubmitted = true

            const response = await ApiService.validateMssLicense({license: this.string})

            if (response.domain === false) {
                this.toast.error('License is not valid')

            } else {
                this.result = response.domain
                this.toast.success('License is valid')
            }
            this.isValidateSubmitted = false
        },
    }
}
</script>
import axios from "@/axios"

const ApiService = {
  async login(data) {
    const response = await axios.post('open/login', data)
    const token = response.data.token
    localStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return response.data
  },
  async logout() {
    const response = await axios.post('logout')
    localStorage.removeItem('token')
    return response.data
  },
  async getMssList() {
    const response = await axios.get('mss')
    return response.data
  },
  async getMssById(id) {
    const response = await axios.get(`mss/${id}`)
    return response.data
  },
  async updateMss(id, data) {
    const response = await axios.put(`mss/${id}`, data)
    return response.data
  },
  async createMss(data) {
    const response = await axios.post(`mss`, data)
    return response.data
  },
  async deleteMssOrder(id) {
    const response = await axios.delete(`mss/${id}`)
    return response.data
  },
  async getMssLicense(data) {
    const response = await axios.post(`mss/license/get`, data)
    return response.data
  },
  async validateMssLicense(data) {
    const response = await axios.post(`mss/license/validate`, data)
    return response.data
  },
  async getOpencartOrderList() {
    const response = await axios.get(`opencart`)
    return response.data
  },
  async getDownloadList() {
    const response = await axios.get(`download`)
    return response.data
  },
  async getMssLicensePublic(token, data) {
    const response = await axios.post(`open/mss/license/get/${token}`, data)
    return response.data
  },
  async getPublic(token) {
    const response = await axios.get(`open/mss/${token}`)
    return response.data
  },
  async getPublicFile(token) {
    const response = await axios.get(`open/download/${token}`, {
      responseType: 'blob'
    })
    return response.data
  },
  async sendEmail(data) {
    const response = await axios.post(`email`, data)
    return response.data
  },
  async getCustomerEmails() {
    const response = await axios.get(`mss/customer/get-email`)
    return response.data
  },
  async searchMss(keyword) {
    const response = await axios.get(`/mss/search/${keyword}`)
    return response.data
  },
}


export default ApiService
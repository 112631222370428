<template>
  <Header />
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <div class="d-flex align-items-center">
          <div class="flex-fill">
            <h4>MSS Order Detail</h4>
            <span v-if="loading" class="text-danger">Loading...</span>
            <span v-if="!loading" class="text-success">Order ID: {{ order.id }}</span>
          </div>
          <div>
            <router-link :to="{ name: 'mss' }" class="btn btn-primary">Back to the list</router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="loading" class="text-center p-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="!loading" class="mt-3 mb-5">
          <div class="col-lg-6 offset-lg-3">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="submitForm">
                  <div class="mb-3">
                    <label class="form-label">Order ID:</label>
                    <input type="text" class="form-control" disabled v-model="order.id">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Opencart Order ID:</label>
                    <input type="text" class="form-control" v-model="order.opencart_order_id">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Domain:</label>
                    <input type="text" class="form-control" v-model="order.domain">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Name:</label>
                    <input type="text" class="form-control" v-model="order.name">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Email:</label>
                    <input type="text" class="form-control" v-model="order.email">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Phone:</label>
                    <input type="text" class="form-control" v-model="order.phone">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">License Key:</label>
                    <input type="text" class="form-control" v-model="order.license">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Total (TL):</label>
                    <input type="text" class="form-control" v-model="order.total">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Registration IP:</label>
                    <input type="text" class="form-control" disabled v-model="order.registered_ip">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Created On:</label>
                    <input type="text" class="form-control" disabled v-model="order.created_at">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Updated On:</label>
                    <input type="text" class="form-control" disabled v-model="order.updated_at">
                  </div>
                  <div class="text-center mt-5 mb-3">
                    <button type="submit" class="btn btn-primary" :disabled="isSubmitted">
                      <span v-show="isSubmitted" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import { useToast } from "vue-toastification"
import * as Utils from '@/utils.js';
import Header from '@/components/panel/Header.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      order: {
        domain: null,
        id: null,
        name: null,
        email: null,
        phone: null,
        total: null,
        license: null,
        registered_ip: null,
        registered_at: null,
        opencart_order_id: null,
        created_at: null,
        updated_at: null
      },
      loading: true,
      requestOrderId: null,
      toast: useToast(),
      isSubmitted: false
    }
  },
  async created() {
    this.requestOrderId = this.$route.params.id

    const response = await ApiService.getMssById(this.requestOrderId)

    this.order = {
      ...response,
      // updated_at: Utils.formatDate(response.updated_at),
      // created_at: Utils.formatDate(response.created_at),
      // registered_at: Utils.formatDate(response.registered_at),
    }

    this.loading = false
  },
  methods: {
    async submitForm() {
      this.isSubmitted = true
      await ApiService.updateMss(this.requestOrderId, this.order)
      this.toast.success("Order updated successfully!");
      this.$router.push({ name: 'mss' })
    },
  }
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import { requireAuth } from '@/middleware/auth';

// common components
import Home from '@/components/Home.vue'
import Login from '@/components/panel/Login.vue'
import NotFound from '@/components/NotFound.vue'

// panel mss components
import MssOrderList from '@/components/panel/mss/MssOrderList.vue'
import MssOrderItem from '@/components/panel/mss/MssOrderItem.vue'
import MssOrderItemNew from '@/components/panel/mss/MssOrderItemNew.vue'
import DownloadList from '@/components/panel/mss/DownloadList.vue'
import License from '@/components/panel/mss/License.vue'

// panel opencart components
import OpencartOrderList from '@/components/panel/opencart/OpencartOrderList.vue'
import PublicOrderDetail from '@/components/panel/opencart/PublicOrderDetail.vue'

// panel general components
import EmailComposer from '@/components/panel/EmailComposer.vue'


const basePanel = '/panel'


const publicRoutes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Utku Okutan'
    },
    component: Home
  },
  {
    path: '/api/:token',
    name: 'public',
    component: PublicOrderDetail,
    meta: {
      title: 'Mesafeli Satış Sözleşmesi'
    },
  },
  {
    path: '/:catchAll(.*)',
    meta: {
      title: '404 Not Found'
    },
    component: NotFound
  }
]

const protectedRoutes = [
  {
    path: basePanel,
    name: 'panel',
    beforeEnter: requireAuth,
    redirect: `${basePanel}/mss`,
  },
  {
    path: `${basePanel}/login`,
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: Login,
  },
  {
    path: `${basePanel}/mss`,
    name: 'mss',
    meta: {
      title: 'MSS Orders'
    },
    beforeEnter: requireAuth,
    component: MssOrderList
  },
  {
    path: `${basePanel}/mss/:id`,
    name: 'mssedit',
    meta: {
      title: 'MSS Order Detail'
    },
    beforeEnter: requireAuth,
    component: MssOrderItem,
  },
  {
    path: `${basePanel}/mss/add`,
    name: 'mssadd',
    meta: {
      title: 'MSS Add Order'
    },
    beforeEnter: requireAuth,
    component: MssOrderItemNew,
  },
  {
    path: `${basePanel}/opencart`,
    name: 'opencart',
    meta: {
      title: 'Opencart Orders'
    },
    beforeEnter: requireAuth,
    component: OpencartOrderList
  },
  {
    path: `${basePanel}/downloads`,
    name: 'downloads',
    meta: {
      title: 'MSS Downloads'
    },
    beforeEnter: requireAuth,
    component: DownloadList
  },
  {
    path: `${basePanel}/license`,
    name: 'license',
    meta: {
      title: 'License Tool'
    },
    beforeEnter: requireAuth,
    component: License
  },
  {
    path: `${basePanel}/email`,
    name: 'email',
    meta: {
      title: 'Send Email'
    },
    beforeEnter: requireAuth,
    component: EmailComposer
  },
]

const routes = [...protectedRoutes, ...publicRoutes]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '';
  next();
});

export default router

<template>
    <div>
        <div v-if="tokenNotFound" class="p-5">
            <h1>Invalid key!</h1>
        </div>
        <div v-if="loading && !tokenNotFound" class="d-flex justify-content-center align-items-center spinner-container">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Yükleniyor...</span>
            </div>
        </div>
        <div v-if="!loading && !tokenNotFound" class="pt-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <div class="flex-fill">
                                        <h4>Opencart 3.x mesafeli satış sözleşmesi modülü</h4>
                                        <span class="text-success">Sipariş numaranız: #{{ order.id }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="mt-4 mb-4">
                                    <div v-if="!isRegistered">
                                        <form @submit.prevent="submitForm">
                                            <p>
                                                Mesafeli Satış Sözleşmesi modülünü sitenizde kullanabilmek için lisans anahtarı oluşturmanız gerekmektedir.
                                            </p>
                                            <p>Kayıt tamamlandıktan sonra lisans anahtarı e-mail adresinize gönderilecektir.
                                            </p>
                                            <p>
                                                İyi günler!
                                            </p>
                                            <div>
                                                <label for="domain" class="form-label fw-bold">Alan adı:</label>
                                                <input type="text" class="form-control" id="domain" v-model="order.domain">
                                                <div v-if="!isDomainValid" class="alert alert-danger mt-2" role="alert">
                                                    <strong>Lütfen alan adını başında www ya da http/https olmadan</strong> yazın. Örnek: siteadi.com ya da dukkan.siteadi.com
                                                </div>
                                            </div>
                                            <div class="mt-3 text-center">
                                                <button class="btn btn-primary" type="submit" :disabled="submitted">
                                                    <span v-show="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    LİSANS OLUŞTUR
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div v-if="isRegistered">
                                        <div class="mt-3 mb-3">
                                            <div class="fs-5">
                                                <p>Alan adı: <span class="text-danger"><strong>{{ order.domain }}</strong></span></p>
                                                <p>Lisans kodunuz: <span class="text-danger"><strong>{{ order.license }}</strong></span></p>
                                            </div>
                                        </div>
                                        <div>
                                            <strong>Modülün güncel versiyonu: </strong> {{ fileversion }}
                                        </div>
                                        <div class="text-center">
                                            <button type="button" class="btn btn-danger mt-3" @click="getFile"
                                                :disabled="isDownLoadSubmitted">
                                                <span v-show="isDownLoadSubmitted" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                                DOSYAYI İNDİR
                                            </button>
                                        </div>
                                        <div class="mt-5">
                                            <h5>OCMOD kurulumu nasıl yapılır?</h5>
                                            <p>
                                                Opencart yönetim panelinize giriş yapın ve menüden "Extensions" sekmesine tıklayın. Burada, sol taraftaki menüden "Installer" seçeneğini seçin. "Upload" düğmesine tıklayarak indirdiğiniz Ocmod uzantısını yükleyin.
                                            </p>
                                            <p>
                                                Uzantının yanındaki "Refresh" düğmesine tıklayarak değişiklikleri yükleyin ve kurulumu tamamlayın. Kurduğunuz Ocmod uzantısının ayarlarını yapılandırmak için sol taraftaki menüden "Extensions" seçeneğine gidin ve "Modifications" seçeneğini seçin.
                                            </p>
                                            <p>
                                                Kurulum ve ayarları tamamladıktan sonra, Opencart sitenizde Ocmod uzantısının doğru şekilde çalışıp çalışmadığını test etmek için sitenizi ziyaret edin
                                            </p>
                                        </div>
                                        <div class="mt-5">
                                            <h5>Modülün VQMOD versiyonu yok mu?</h5>
                                            <p>
                                                VQMOD'un Opencart 3.x versiyonu ile performanslı çalışmaması sebebi ile modülün VQMOD versiyonu kaldırılmıştır.
                                            </p>
                                        </div>
                                        <div class="mt-5">
                                            <h5>Çalışan bir sistemim var. Güncelleme yapabilir miyim?</h5>
                                            <p>
                                                Sisteminiz sorunsuz çalışıyorsa <strong>güncelleme yapmanız tavsiye edilmez.</strong>
                                            </p>
                                            <p>
                                                Kurulumu öncelikle localhost üzerinde test ederek, daha sonra canlı sisteminize kurmanız tavsiye edilir.
                                            </p>
                                            <p>
                                                Güncelleme yapmak istiyorsanız, öncelikle sisteminizin yedeğini aldıktan sonra mevcut ise (VQMOD/OCMOD) modülü kaldırmanız gerekmektedir.
                                            </p>
                                            <p>
                                                Daha sonra yukarıdaki OCMOD kurulum adımlarını takip ederek modülü kurabilirsiniz.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import { useToast } from "vue-toastification";
export default {
    data() {
        return {
            order: {
                id: null,
                created_at: null,
                domain: null,
                license: null,
                registered_at: null,
            },
            loading: true,
            isDomainValid: true,
            toast: useToast(),
            fileversion: 'v3.1',
            filename: 'mss_v3.ocmod.zip',
            isRegistered: false,
            submitted: false,
            isDownLoadSubmitted: false,
            tokenNotFound: false,
        }
    },
    async created() {
        try {
            const response = await ApiService.getPublic(this.$route.params.token)
            this.order = response
            if (response.license) {
                this.isRegistered = response.license ? true : false
            }
        } catch (error) {
            this.tokenNotFound = true
        }

        this.loading = false
    },
    methods: {
        async submitForm() {
            if (this.order.domain === null || this.order.domain === '') {
                this.toast.error("Alan adı boş bırakılamaz!")
                return
            }

            if (!this.validateDomain(this.order.domain)) {
                this.isDomainValid = false
                return
            }

            this.submitted = true

            try {
                const response = await ApiService.getMssLicensePublic(
                    this.$route.params.token,
                    { domain: this.order.domain }
                )
                this.order.license = response.license
                this.order.registered_at = response.registered_at
                this.domain = response.domain
                this.isRegistered = true
                this.toast.success("Lisans başarıyla oluşturuldu!")
            } catch (error) {
                this.toast.error("Lisans oluşturulamadı!")
                console.log(error)
            }
            this.submitted = false
        },
        validateDomain(domain) {
            return !/https?:\/\/|www\./.test(domain);
        },
        async getFile() {
            this.isDownLoadSubmitted = true
            const response = await ApiService.getPublicFile(this.$route.params.token)
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.isDownLoadSubmitted = false
        }
    }
}
</script>
<style scoped>
.spinner-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Optional: add a semi-transparent overlay */
}
</style>
<template>
  <Header />
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
          <div class="d-flex align-items-center">
            <div class="flex-fill">
              <h4>MSS Add Order</h4>
              <span v-if="loading" class="text-danger">Loading...</span>
              <span v-if="!loading" class="text-success">Create a new order</span>
            </div>
            <div>
              <router-link :to="{ name: 'mss' }" class="btn btn-primary">Back to the list</router-link>
            </div>
          </div>
        </div>
      <div class="card-body">
        <div v-if="loading" class="text-center p-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="!loading" class="mt-3 mb-5">
          <div class="col-lg-6 offset-lg-3">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="submitForm">
                  <div class="mb-3">
                    <label class="form-label">Name:</label>
                    <input type="text" class="form-control" v-model="order.name">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Email:</label>
                    <input type="text" class="form-control" v-model="order.email">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Phone:</label>
                    <input type="text" class="form-control" v-model="order.phone">
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Total (TL):</label>
                    <input type="text" class="form-control" v-model="order.total">
                  </div>
                  <div class="text-center mt-5 mb-3">
                    <button type="submit" class="btn btn-primary" :disabled="isSubmitted">
                          <span v-show="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Add
                      </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import { useToast } from "vue-toastification";
import Header from '@/components/panel/Header.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      order: {
        name: null,
        email: null,
        phone: null,
        total: null,
      },
      loading: true,
      toast: useToast(),
      isSubmitted: false
    }
  },
  created() {
    this.loading = false
  },
  methods: {
    async submitForm() {
      if (this.order.email === '') {
        this.toast.error('Email is required')
        return
      }
      this.isSubmitted = true
      await ApiService.createMss(this.order)
      this.$router.push({ name: 'mss' })
    }
  }
}
</script>
<template>
  <div class="wrapper">
    <div class="header">
      <h1>Utku Okutan</h1>
    </div>
    <div class="main">
      <div class="section">
        <p>I specialize in both frontend and backend development, focusing on crafting responsive user interfaces and building powerful web applications. For backend development, I work with frameworks like Laravel for PHP and utilize Node.js. Additionally, I leverage JavaScript for various aspects of web development, ensuring seamless integration and dynamic functionality. I also integrate Vue.js to create robust and efficient user interfaces. My passion lies in harnessing these tools to craft cutting-edge web experiences.</p>
        <p>If you're considering a potential project or have any inquiries, feel free to reach out to me at <a href="mailto:contact@utkuokutan.com">contact@utkuokutan.com</a>. I thrive on collaborating with clients to transform their concepts into tangible, digital solutions. Be it a straightforward website or a complex web application, I'm here to make it happen.</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Playfair+Display:wght@500&display=swap');

/* Global styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-family: 'Playfair Display', serif;
}

h1 {
  color: #4683dd;;
}

a {
  color: #4683dd;
}

a:hover {
  color: #a40505;
}

p {
  margin-bottom: 30px;
}

.wrapper {
  font-family: 'Open Sans', sans-serif;
  line-height: 2;
}

/* Main content */
.main {
  max-width: 800px;
  margin: 90px auto 0px;
  font-size: 22px;
  line-height: 1.7;
}

/* Header */
.header {
  background-color: #f0f0f0;
  letter-spacing: 3px;
  padding: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  p {
    margin-bottom: 20px;
  }
  .header {
    padding: 25px;
  }
  .main {
    margin: 20px auto 0px;
    padding: 20px;
    font-size: 18px;
    line-height: 1.6;
  }
}
</style>
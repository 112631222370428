<template>
  <Header />
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <div class="flex-fill">
              <h4>MSS Order List</h4>
              <span v-if="loading" class="text-danger">Loading...</span>
              <span v-if="!loading" class="text-success">
                <strong>Total orders:</strong> {{ orders.length }}<br><strong>Grand total:</strong> {{ grandTotal }} TL (some order prices are missing)
              </span>
            </div>
            <div v-if="!loading">
              <router-link :to="{ name: `mssadd` }" class="btn btn-primary">Add New</router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="text-center p-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-if="!loading" class="mt-1">
            <div class="table-responsive">
              <table class="table table-border">
                <thead>
                  <tr>
                    <th class="text-nowrap">Order ID</th>
                    <th class="text-nowrap">Opencart ID</th>
                    <th class="text-nowrap">Domain</th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">E-mail</th>
                    <th class="text-nowrap">Total (TL)</th>
                    <th class="text-nowrap">Phone</th>
                    <th class="text-nowrap">Date Added</th>
                    <!-- <th class="text-nowrap">Date Updated</th> -->
                    <th class="text-nowrap">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order) in orders" :key="order.id">
                    <td>{{ order.id }}</td>
                    <td>{{ order.opencart_order_id }}</td>
                    <td>
                      <a :href="'http://' + order.domain" target="_blank">{{ order.domain }}</a>
                    </td>
                    <td :class="{ 'text-danger': order.name == 'Utku Okutan' }">{{ order.name }}</td>
                    <td>{{ order.email }}</td>
                    <td>{{ order.total ? order.total : '' }}</td>
                    <td>{{ order.phone }}</td>
                    <td>{{ order.created_at }}</td>
                    <!-- <td>{{ order.updated_at }}</td> -->
                    <td class="text-nowrap">
                      <router-link :to="{ name: 'mssedit', params: { id: order.id } }" class="btn btn-primary btn-sm">Edit</router-link>
                      <button @click="deleteOrder(order.id)" type="button" class="btn btn-danger btn-sm ms-2">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import { useToast } from 'vue-toastification';
import * as Utils from '@/utils.js';
import Header from '@/components/panel/Header.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      orders: [],
      loading: true,
      toast: useToast(),
      grandTotal: 0
    }
  },
  async created() {
    await this.getOrders()
  },
  methods: {
    async getOrders() {
      const response = await ApiService.getMssList()

      this.orders = response.map(order => {
        order.created_at = Utils.formatDate(order.created_at)
        order.updated_at = Utils.formatDate(order.updated_at)
        order.registered_at = Utils.formatDate(order.registered_at)
        return order
      });

      if (this.orders && Array.isArray(this.orders)) {
        this.grandTotal = this.orders.reduce((total, order) => {
          if (order.total !== null) {
            return total + parseInt(order.total);
          } else {
            return total;
          }
        }, 0);
      }

      this.loading = false
    },
    async deleteOrder(id) {
      if (!confirm('Are you sure?')) return

      await ApiService.deleteMssOrder(id)

      this.orders = this.orders.filter(order => order.id != id)

      this.toast.success('Order deleted successfully')
    }
  }
}
</script>